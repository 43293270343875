export default {
  formDataGroups: [
    {
      title: 'General Information',
      name: 0,
      formDataItems: [
        {
          span: 9,
          formtype: 'input',
          prop: 'code',
          label: 'Code',
          placeholder: 'Please enter the Code',
          object: {
          }
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'category',
          label: 'Category',
          placeholder: 'Please enter the Category',
          object: {
          }
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'name',
          label: 'Diagnosis',
          placeholder: 'Please enter the Diagnosis',
          object: {
          }
        }
      ]
    }
  ]
}